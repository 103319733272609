// Invoicer.js

import React, { useState, useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, firestore, timestamp } from '../firebase';
import { generatePdf } from './generatePdf.js'; // Import the generatePdf function from pdfUtils
import logo from '../navbar/header/logo.png';

const Invoicer = () => {
  const [user] = useAuthState(auth);
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [services, setServices] = useState([{ description: '', amount: 0 }]);
  const [invoiceNumber, setInvoiceNumber] = useState(1000);
  const [error, setError] = useState(null);
  const [pdfBlob, setPdfBlob] = useState(null);
  const [gstPercentage, setGstPercentage] = useState(0);
  const [pdfFileName, setPdfFileName] = useState('');
  const [discount, setDiscount] = useState(0);
  const [discountReason, setDiscountReason] = useState('');
  const [formFilled, setFormFilled] = useState(false);
  const [pdfGenerating, setPdfGenerating] = useState(false);
  const navigate = useNavigate();

  const isFormFilled = () => {
    return name.trim() !== '' && address.trim() !== '' && services.length > 0 && services.every(service => service.description.trim() !== '' && service.amount > 0);
  };

  const handleFindInvoice = () => {
    navigate ('/finder');
  };

  const generateInvoiceNumber = async () => {
    try {
      const latestInvoiceNumberRef = firestore.collection('invoiceNumbers').doc('latestInvoiceNumber');
      const snapshot = await latestInvoiceNumberRef.get();
      let latestInvoiceNumber = snapshot.exists ? snapshot.data().number : 999;
  
      const checkInvoiceExists = async (currentInvoiceNumber) => {
        try {
          const invoiceRef = firestore.collection('invoices').where('invoiceNumber', '==', currentInvoiceNumber);
          const snapshot = await invoiceRef.get();
          return !snapshot.empty;
        } catch (error) {
          console.error('Error checking invoice existence:', error);
          return false;
        }
      };
  
      while (await checkInvoiceExists(latestInvoiceNumber)) {
        latestInvoiceNumber++;
      }
  
      await latestInvoiceNumberRef.set({ number: latestInvoiceNumber });
      return latestInvoiceNumber;
    } catch (error) {
      console.error('Error generating invoice number:', error);
      throw error;
    }
  };

  useEffect(() => {
    const generateInvoice = async () => {
      try {
        if (!user) return;
  
        const invoiceNumber = await generateInvoiceNumber();
        setInvoiceNumber(invoiceNumber);
      } catch (error) {
        setError('Failed to generate invoice number');
      }
    };
  
    generateInvoice();
  }, [user]);

  useEffect(() => {
    setPdfFileName(`${name}_${invoiceNumber}.pdf`);
  }, [name, invoiceNumber]);

  const handleAddService = () => {
    setServices([...services, { description: '', amount: 0 }]);
  };

  const handleRemoveService = (indexToRemove) => {
    setServices((prevServices) => {
      return prevServices.filter((service, index) => index !== indexToRemove);
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await firestore.collection('invoices').add({
        invoiceNumber,
        name,
        address,
        services,
        gstPercentage,
        discount,
        discountReason,
        timestamp: timestamp(),
      });
      generateInvoiceNumber();
      setPdfGenerating(true);
      const generatedPdfBlob = await generatePdf(null, services, name, address, invoiceNumber, gstPercentage, discount, discountReason); // Pass necessary data to generatePdf function
      setPdfBlob(generatedPdfBlob);
    } catch (error) {
      setError('Failed to generate invoice');
      console.error('Error generating invoice:', error);
    }
  };

  const handleResetForm = async () => {
    setName('');
    setAddress('');
    setServices([{ description: '', amount: 0 }]);
    setPdfGenerating(false);
  
    try {
      // Generate a new invoice number when resetting the form
      const updatedInvoiceNumber = await generateInvoiceNumber();
      setInvoiceNumber(updatedInvoiceNumber);
    } catch (error) {
      setError('Failed to generate invoice number');
      console.error('Error generating invoice number:', error);
    }
  };

  if (!user) {
    return <Navigate to="/admin" />;
  }

  return (
    <div style={{}}>
      <div style={{ height: '150px'}}> </div>
      <h2>Invoicer</h2>
      {error && <p>{error}</p>}
      <form onSubmit={handleSubmit} style={{ width:'100%', display:'flex', flexDirection:'column', justifyContent:'center', gap:'10px', borderBottom: '2pt solid #333', marginTop:'auto', padding:'10px'}}>
        <div style={{width:'100%', display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center', flexWrap:'wrap', gap:'2%'}}>
          <label>Name:</label>
          <input type="text" value={name} onChange={(e) => setName(e.target.value)} style={{ width: '70%', maxWidth: '600px', height:'30px'}}/>
        </div>
        <div style={{width:'100%', display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center', flexWrap:'wrap', gap:'2%'}}>
          <label>Address:</label>
          <input type="text" value={address} onChange={(e) => setAddress(e.target.value)} style={{ width: '70%', maxWidth: '600px', height:'30px'}}/>
          </div>
        {services.map((service, index) => (
          <div key={index} style={{width: '100%', display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'center', gap:'1%', flexWrap:'wrap'}}>
            <label>Description:</label>
            <input
              type="text"
              value={service.description}
              style={{height:'30px', width:'30%', maxWidth: '700px'}}
              onChange={(e) => {
                const newServices = [...services];
                newServices[index].description = e.target.value;
                setServices(newServices);
              }}
            />
            <label>Amount:</label>
            <input
              type="number"
              value={service.amount}
              style={{height:'30px', maxWidth: '100px'}}
              onChange={(e) => {
                const newServices = [...services];
                newServices[index].amount = parseFloat(e.target.value);
                setServices(newServices);
              }}
            />
            <button type="button" onClick={() => handleRemoveService(index)} style={{color:'red'}}>Remove</button>
          </div>
        ))}
        <button type="button" onClick={handleAddService} style={{height:'30px', padding:'0 10px', color:'green', width:'20%', margin:'auto'}}>Add Service</button>
        <div><label>Discount:</label>
          <input type="text" value={discount} onChange={(e) => setDiscount(e.target.value)} style={{height:'30px', maxWidth: '100px', marginLeft:'10px'}}/>
        </div>
        <div><label>Discount Reason:</label>
          <select value={discountReason} onChange={(e) => setDiscountReason(e.target.value)} style={{height:'30px', maxWidth: '200px', marginLeft:'10px'}}>
            <option value={''}>None</option>
            <option value={'Student'}>Student</option>
            <option value={'Senior'}>Senior</option>
            <option value={'Senior'}>Multiple filings</option>
          </select>
        </div>
        <div>
          <label>Select GST Percentage:</label>
          <select value={gstPercentage} onChange={(e) => setGstPercentage(parseInt(e.target.value))} style={{height:'30px', maxWidth: '100px'}}>
            <option value={0}>0%</option>
            <option value={13}>13%</option>
          </select>
        </div>
          <div style={{width:'100%', display:'flex', flexDirection:'row', justifyContent:'center', gap:'20px', borderBottom: '2pt solid #333', marginTop:'20px', paddingBottom:'20px'}}>
            <button type="button" onClick={handleResetForm} style={{height:'30px', padding:'0 10px', color:'red'}}>Reset Form</button>
            <button type="submit" disabled={!isFormFilled() || pdfGenerating} style={{height:'30px', padding:'0 10px', color:'green'}}>Generate Invoice</button>
            <button type="button" onClick={handleFindInvoice} style={{height:'30px', padding:'0 10px', color:'Orange'}}>Find Invoice</button>
          </div>
          {pdfBlob && (
          <a href={window.URL.createObjectURL(pdfBlob)} download={pdfFileName} style={{maxWidth:'500px',margin:'auto', height:'30px', padding:'0 10px', color:'green', border:'2px solid green', padding:'5px'}}>
          Download Invoice PDF {pdfFileName}
          </a>)}
      </form>
    </div>
  );
};

export default Invoicer;
