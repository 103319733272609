import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; 
import { auth } from '../firebase';


const SignInWithEmail = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate(); // Get the history object

  const signInWithEmailAndPassword = async (e) => {
    e.preventDefault();
    try {
      await auth.signInWithEmailAndPassword(email, password);
      navigate('/invoicer'); 
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div>
      <h2>Sign In With Email and Password</h2>
      {error && <div>{error}</div>}
      <form onSubmit={signInWithEmailAndPassword} style={{width: '100%', display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'center', gap:'1%', flexWrap:'wrap'}}>
        <input
          type="email"
          placeholder="Email"
          style={{height:'30px', padding:'0 10px', color:'green'}}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          type="password"
          placeholder="Password"
          style={{height:'30px', padding:'0 10px', color:'green'}}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button type="submit" style={{height:'30px', padding:'0 10px', color:'green'}}>Sign In</button>
      </form>
    </div>
  );
};

export default SignInWithEmail;
