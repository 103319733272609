import React from 'react';
import SignInWithEmail from './SignInWithEmail';

const SignInPage = () => {
  return (
    <div style={{margin:"auto", width:"80%"}}>
      <div style={{height:"200px"}}></div>
      <h1>Log in to generate or view invoices</h1>
      <SignInWithEmail />
    </div>
  );
};

export default SignInPage;
