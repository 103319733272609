import React from 'react';
import * as emailjs from 'emailjs-com';
import './form.css';

class ContactForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      message: ''
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.resetForm = this.resetForm.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleSubmit(event) {
    event.preventDefault();
    const { name, email, subject, message } = this.state;
    const templateParams = {
      from_name: name,
      from_email: email,
      to_name: 'asmitastaxhouse@gmail.com', 
      subject,
      message,
      email,
      name,
      message_html: message,
    };
    emailjs.send(
      'service_dkcrim4',
      'template_8eu104t',
       templateParams,
      'UdBItkjPb7PDiFHCI'
    )
    this.resetForm();
    alert("Message sent!")
  };

  resetForm() {
    this.setState({
      name: '',
      email: '',
      message: '',
    });
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  render() {
    const { name, email, subject, message, sentMessage } = this.state;

    return (
      <form onSubmit={this.handleSubmit} className="contact-form">
        <fieldset className="contact-form-group">
            <input
              name="name"
              type="text"
              placeholder="Your Name"
              value={name}
              onChange={this.handleChange}
              required
            />
        </fieldset>
        <fieldset className="contact-form-group">
            <input
              name="email"
              type="text"
              placeholder="Email/Phone number"
              value={email}
              onChange={this.handleChange}
              required
            />
        </fieldset>
        <fieldset className="contact-form-group">
            <textarea
              name="message"
              placeholder="Message"
              value={message}
              onChange={this.handleChange}
            />
        </fieldset>

        <fieldset className="contact-form-group">
            <button color="dark" className="submitButton" >Send</button>
            <button text className="cancelButton" >Cancel</button>
        </fieldset>
      </form>
    );
  }
}

export default ContactForm;