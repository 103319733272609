import React, { useState, useEffect } from 'react';
import { auth, firestore } from '../firebase.js';
import { generatePdf } from '../invoicer/generatePdf.js';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Navigate, useNavigate } from 'react-router-dom';

const InvoiceList = () => {
  const [user] = useAuthState(auth);
  const [invoices, setInvoices] = useState([]);
  const [filteredInvoices, setFilteredInvoices] = useState([]); // State to store filtered invoices
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate();


  useEffect(() => {
    const fetchInvoices = async () => {
      try {
        const snapshot = await firestore.collection('invoices').get();
        const fetchedInvoices = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setInvoices(fetchedInvoices);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching invoices:', error);
      }
    };

    fetchInvoices();
  }, []);

  const handleCreateInvoice = () => {
    navigate ('/invoicer');
  };

  useEffect(() => {
    // Filter invoices based on search query
    const filtered = invoices.filter(invoice =>
      invoice.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      invoice.invoiceNumber.toString().includes(searchQuery)
    );
    setFilteredInvoices(filtered);
  }, [invoices, searchQuery]);

  const handleDownloadPdf = async (invoice) => {
    try {
      const pdfBlob = await generatePdf(null, invoice.services, invoice.name, invoice.address, invoice.invoiceNumber, invoice.gstPercentage, invoice.discount, invoice.discountReason);
      
      // Create a temporary anchor element
      const downloadLink = document.createElement('a');
      downloadLink.href = window.URL.createObjectURL(pdfBlob);
      downloadLink.download = `${invoice.name}_${invoice.invoiceNumber}.pdf`; // Set the filename
      downloadLink.style.display = 'none';
      
      // Append the anchor to the body and trigger a click event
      document.body.appendChild(downloadLink);
      downloadLink.click();
      
      // Clean up
      document.body.removeChild(downloadLink);
    } catch (error) {
      console.error('Error generating PDF:', error);
    }
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  if (!user) {
    return <Navigate to="/admin" />;
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div style={{width:'100%', maxWidth:'800px', margin:'auto', display:'flex', flexDirection:'column', flexWrap:'wrap'}}>
      <div style={{ height: '150px' }}></div>
      <h2 style={{borderBottom:'2px solid #333'}}>Invoice List</h2>
      <div style={{width:'100%', display:'flex', flexDirection:'row', justifyContent: 'space-between', alignItems:'center'}}>
      <input 
        type="text" 
        placeholder="Search by name or invoice number" 
        value={searchQuery} 
        onChange={handleSearch} 
        style={{width:'100%', maxWidth:'300px', margin:'auto', height:'25px', marginTop:'20px', marginBottom:'20px'}}
      />
      <button type="button" onClick={handleCreateInvoice} style={{height:'30px', padding:'0 10px', color:'Orange'}}>Go to Invoicer</button>
      </div>
      <table>
        <thead>
          <tr>
            <th style={{borderBottom:'2px solid #333'}}>Invoice Number</th>
            <th style={{borderBottom:'2px solid #333'}}>Name</th>
            <th style={{borderBottom:'2px solid #333'}}>Address</th>
            <th style={{borderBottom:'2px solid #333'}}>Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredInvoices.map(invoice => (
            <tr key={invoice.id}>
              <td>{invoice.invoiceNumber}</td>
              <td>{invoice.name}</td>
              <td>{invoice.address}</td>
              <td>
                <button onClick={() => handleDownloadPdf(invoice)}>Download PDF</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default InvoiceList;
