import React from 'react';
import Footer from '../footer'
import styles from '../footer/footer.module.css';
import Year from './year.jpeg';
import Tax from './tax.jpeg';
import TaxFile from './taxfile.jpeg'

export default function Product(){
    return (
        <div>
            <div style={{height:'100px'}}></div>
            <h1 style={{marginBottom:'20px',borderBottom:'1px solid', width:'270px', marginLeft:'auto', marginRight:'auto'}}>OUR SERVICES</h1>
            <p className={styles.para}>We provide a wide range of services that includes personal tax filing whether you are a student, an employee or a recent immigrant who isn’t well versed with the tax lingo. Our priority is to optimize the tax return by compiling all necessary documents which best reflect your income and expenses.</p>
            <hr style={{width:'200px', marginLeft:'auto', marginRight:'auto', marginTop:'20px', borderBottom:'1px solid'}}/>
            <div className={styles.imgBox}>
                <div className={styles.productBox}>
                    <img src={TaxFile} style={{width:'150px'}} alt=''/>
                    <div className={styles.textBox}>
                        <h2 className={styles.textHeader}><span style={{borderBottom:'1px solid red'}}>PERSONAL TAX FILE</span></h2>
                        <p className={styles.textPara}>We file personal taxes with the exception of self emplpoyed taxes. Our services include prior year taxes, tax adjustments, new immigrant tax services and tax audit services.</p>
                    </div>
                </div>
                <div className={styles.productBox}>
                    <div className={styles.textBox}>
                        <h2 className={styles.textHeaderInv}><span style={{borderBottom:'1px solid red'}}>YEAR-ROUND SERVICE</span></h2>
                        <p className={styles.textParaInv}>Tax filing doesn’t stop at sending the tax return to the CRA. It needs follow up and planning through-out the year. We are with you every step of the way and are available all year round regarding any tax related matter and with your communication with the CRA.</p>
                    </div>
                    <img src={Year} style={{width:'150px'}} alt='' className={styles.secondPic}/>
                </div>
                <div className={styles.productBox}>
                <img src={Tax} style={{width:'150px'}} alt=''/>
                    <div className={styles.textBox}>
                        <h2 className={styles.textHeader}><span style={{borderBottom:'1px solid red'}}>FREE FINANCIAL ADVICE</span></h2>
                        <p className={styles.textPara}>We pride ourselves in having extensive knowledge in personal financial and tax planning. We provide you with a compiled document consisting of important slips/documents you should consider when filing your tax return to ensure that the return is fully optimized and complete.</p>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}
