import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyArK5eOfjesdiooCEKA35xNNeLGOQUjgAI",
    authDomain: "asmitastaxhouse-60d4d.firebaseapp.com",
    projectId: "asmitastaxhouse-60d4d",
    storageBucket: "asmitastaxhouse-60d4d.appspot.com",
    messagingSenderId: "608681527757",
    appId: "1:608681527757:web:dda8e6e4d362c1e15a1c84",
    measurementId: "G-FYMVGZ8XBS"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);

export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const timestamp = firebase.firestore.FieldValue.serverTimestamp;

export default firebase;