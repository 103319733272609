// generatePdf.js

import { Document, Page, Text, View, StyleSheet, pdf, Image } from '@react-pdf/renderer';
import { firestore } from '../firebase';
import logo from '../navbar/header/logo.png';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: 40,
    paddingHorizontal: 20,
  },
  logoContainer: {
    position: 'absolute',
    top: 20,
    left: 20,
    width: '40%',
  },
  logo: {
    width: '100%',
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    margin: '20px',
  },
  subtitle1: {
    fontSize: 14,
    marginBottom: 5,
  },
  subtitle2: {
    fontSize: 14,
    marginBottom: 5,
  },
  serviceTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    margin: '10px',
  },
  serviceDescription: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  serviceAmount: {
    fontSize: 14,
  },
  footer: {
    width: '100%',
    marginTop: 20,
    borderTop: '2pt solid #333',
    paddingTop: 10,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  subtotal: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  gst: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  total: {
    fontSize: 16,
    fontWeight: 'bold',
    marginTop: 10,
  },
});

export const generatePdf = async (invoiceData, services, name, address, invoiceNumber, gstPercentage, discount, discountReason) => {
  try {
    const querySnapshot = await firestore.collection('invoices').where('invoiceNumber', '==', invoiceNumber).get();
    if (!querySnapshot.empty) {
      const invoiceDoc = querySnapshot.docs[0];
      const invoiceData = invoiceDoc.data();

      const invoiceTimestamp = invoiceData.timestamp.toDate();
      const invoiceDate = new Date(invoiceTimestamp);
      const dueDate = new Date(invoiceDate);
      dueDate.setDate(invoiceDate.getDate() + 14);

      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      const formattedInvoiceDate = invoiceDate.toLocaleDateString('en-US', options);
      const formattedDueDate = dueDate.toLocaleDateString('en-US', options);

      const subtotal = services.reduce((acc, service) => acc + service.amount, 0);
      const gstAmount = (subtotal * gstPercentage) / 100;
      const discountAmount = discount;
      const total = subtotal + gstAmount - discountAmount;

      const pdfDocument = (
        <Document>
          <Page size="Letter" style={styles.page}>
            <View style={styles.logoContainer}>
              <Image src={logo} style={styles.logo} />
            </View>
            <Text style={styles.title}>Invoice</Text>
            <View style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', borderBottom: '2pt solid #333' }}>
              <View>
                <Text style={styles.subtitle2}>Name: {name}</Text>
                <Text style={styles.subtitle2}>Address: {address}</Text>
              </View>
              <View>
                <Text style={styles.subtitle1}>Invoice Number: {invoiceNumber}</Text>
                <Text style={styles.subtitle1}>Invoice Date: {formattedInvoiceDate}</Text>
                <Text style={styles.subtitle1}>Due Date: {formattedDueDate}</Text>
              </View>
            </View>
            <Text style={styles.serviceTitle}>Services:</Text>
            <View style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
              {services.map((service, index) => (
                <View key={index} style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                  <Text style={styles.serviceDescription}>{service.description}</Text>
                  <Text style={styles.serviceAmount}>${service.amount}</Text>
                </View>
              ))}
            </View>
            <View style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', borderTop: '2pt solid #333', marginTop: '20px' }}>
              <View style={{ width: '50%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <Text style={{fontSize:'16px', fontWeight:'light'}}>Please remit payment via e-transfer to asmitastaxhouse@gmail.com within the due date.</Text>
              </View>
              <View>
                <Text style={styles.subtotal}>Subtotal: ${subtotal.toFixed(2)}</Text>
                <Text style={styles.subtotal}> {discountReason} Discount: ${discountAmount}</Text>
                <Text style={styles.gst}>GST/HST ({gstPercentage}%): ${gstAmount.toFixed(2)}</Text>
                <Text style={styles.total}>Total: ${total.toFixed(2)}</Text>
              </View>
            </View>
          </Page>
        </Document>
      );

      const pdfBlob = await pdf(pdfDocument).toBlob();
      return pdfBlob;
    } else {
      console.log("No invoice found for the given invoice number");
      return null;
    }
  } catch (error) {
    console.error('Error generating PDF:', error);
    throw new Error('Failed to generate PDF');
  }
};
