import React from 'react';
import Container from 'react-bootstrap/Container';

export default function NotFound(){
    return(
        <Container>
            <div style={{height:"200px"}}></div>
            <h1 className="text-center">Page Not Found</h1>
        </Container>
    )
}
