import React from 'react';
import { Route, Routes, BrowserRouter as Router }  from 'react-router-dom';
import Home from './components/home/';
import Products from './components/products/';
import Contact from './components/contact/';
import NotFound from './components/404';
import SignInPage from './components/google';
import Invoicer from './components/invoicer';
import InvoiceFinder from './components/invoiceFinder';

export default function Routess() {
    return(
    <Router>
        <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="/products" element={<Products />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/admin" element={<SignInPage />} />
            <Route path="/invoicer" element={<Invoicer />} />
            <Route path="/finder" element={<InvoiceFinder />} />
            <Route path="*" element={<NotFound />} />
        </Routes>
    </Router>
    )
};
