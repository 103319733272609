import React from 'react';
import styles from './footer.module.css';

export default function Footer() {
    return (
        <div style={{marginTop:'30px'}}>
            <h1 style={{margin:'60px 0 20px 0', borderBottom:'1px solid', width:'180px', marginLeft:'auto', marginRight:'auto'}}>Contact Us</h1>
            <h2 style={{margin:'20px 0 20px 0'}}>Have any questions?</h2>
            <p style={{margin:'20px 0 20px 0'}}>Text/Whatsapp me: &nbsp;<a style={{color:'red'}} href=''>+1 (647) 535-7801</a></p>
            <a style={{margin:'20px 0 20px 0'}} href="mailto:asmitastaxhouse@gmail.com">Email me at: <span style={{color:'red'}}>asmitastaxhouse@gmail.com</span></a>
            {/* <p style={{margin:'20px 0 20px 0'}}>Visit Instagram page: &nbsp;<a style={{color:'red'}} href=''>@Asmitastaxhouse</a></p>
            <p style={{margin:'20px 0 20px 0'}}>FOLLOW ME ON INSTAGRAM</p>
            <a href=''>@Asmitastaxhouse</a> */}
            <p className={styles.footerBottom}>COPYRIGHTS © ASMITASTAXHOUSE | ALL RIGHTS RESERVED</p>
        </div>
    )
}
