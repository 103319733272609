import React from "react";
import Header from "./header/Header";
import "./styles.css";

export default function App() {
  return (
    <div className="App" >
      <Header />
    </div>
  );
}
