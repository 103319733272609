import React from 'react';
import ContactForm from './sendMail';
import Footer from '../footer';
export default function Contact() {
    return (
        <div>
            <div style={{height:'100px'}}></div>
            <h2 style={{margin:'20px 0 0 0'}}>Please fill out this from to contact us and we will get back to you as soon as possible.</h2>
            <ContactForm/>
            <Footer/>
        </div>
    )
}
