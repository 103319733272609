import React from 'react';
import styles from './index.module.css';
import Logo from '../../navbar/header/logo.png';
import Footer from '../../footer';
import Year from '../../products/year.jpeg';
import Tax from '../../products/tax.jpeg';
import TaxFile from '../../products/taxfile.jpeg';
import Pdf from './taxChecklist.pdf';

// const Images= [
//     Logo
// ]

export default function Home2 (){
    return (
        <div>
            <div className={styles.mainbox}>
                <h1 className={styles.header1}>WELCOME TO</h1>
                <img src={Logo} className={styles.logo} alt="logo"/>
                <p className={styles.para}>Filing for taxes can seem daunting for many. We acknowledge that everyone has a unique tax situation and we’re here to help! We can assist you in gathering all necessary documents in order to report income and losses as required. Some of the documentation needed for successful tax planning include:</p>
                <ul style={{textAlign:'start',maxWidth:'600px', marginLeft:'auto', marginRight:'auto', fontSize:'18px'}}>
                    <li>T3 statements relating to ETFs, REITS, and mutual funds</li>
                    <li>T4 statements demonstrating employment income, RRIF, RRSP and other types of income</li>
                    <li>T5 investment income statement</li>
                    <li>RRSP contributions</li>
                    <li>Employment expense statements or receipts</li>
                    <li>Tuition statements</li>
                    <li>Medical and healthcare receipts</li>
                    <li>Charitable and political donation receipts</li>
                    <li>Adoption and child-related receipts</li>
                </ul> 
            </div>
            <div style={{marginBottom:'30px',marginTop:'20px'}}> 
                <p style={{marginBottom:'10px'}}>Pease click on the link below to see the complete checklist of slips/documents that applies to you:</p>
                <a href={Pdf} target="_blank" className={styles.download}>Download PDF</a>
            </div>
            {/* <div style={{marginBottom:'30px'}}>
            <a href="/products" style={{borderBottom:'1px solid', width:'220px', marginLeft:'auto',marginTop:'100px', marginRight:'auto', fontSize:'30px'}}>Highlights</a>
                <ImgViewer images={Images}/>
            </div> */}
            <div>
                <a href="/products" style={{borderBottom:'1px solid', width:'220px', marginLeft:'auto',marginTop:'100px', marginRight:'auto', fontSize:'30px'}}>Our Services</a>
                <div className={styles.products} >
                    <a className={styles.productBox} href="/products">
                        <img src={TaxFile} className={styles.threeLogos} alt='PERSONAL TAX FILE'/>
                        <a style={{fontSize:'24px'}}>PERSONAL TAX FILE</a>
                    </a>
                    <a className={styles.productBox} href="/products">
                        <img src={Year} className={styles.threeLogos} alt='YEAR-ROUND SERVICE'/>
                        <a style={{fontSize:'23px'}}>YEAR-ROUND SERVICE</a>
                    </a>
                    <a className={styles.productBox} href="/products">
                    <img src={Tax} className={styles.threeLogos} alt='FREE FINANCIAL ADVICE'/>
                        <a style={{maxFontSize:'24px'}}>FREE FINANCIAL ADVICE</a>
                        {/* <img src={Logo} style={{width:'190px', marginLeft:'auto', marginRight:'auto'}} alt=''/> */}
                    </a>
                </div>
            </div>
            <Footer/>
        </div>
    );
};